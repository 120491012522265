import cx from 'classnames';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { HeaderMenu } from 'summer';
import { toggleMobileSidebar } from '../../../store/ducks/global.duck';
import ControlePontoForm from '../../pages/controlePonto/ControlePontoForm';
import HeaderSearch from './HeaderSearch';
import HeaderUserbox from './HeaderUserBox';

import './Header.css';

export interface HeaderProps {
  sidebarToggleMobile?: boolean;
}

const Header: FC<HeaderProps> = ({ sidebarToggleMobile }) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const openBaterPontoModal = () => setIsOpen(!isOpen);

  const onClick = () => dispatch(toggleMobileSidebar());

  return (
    <>
      <div className="app-header app-header--shadow app-header--opacity-bg">
        <div className="app-header--pane">
          <button
            onClick={onClick}
            className={cx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', { 'is-active': sidebarToggleMobile })}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <HeaderSearch />
          <HeaderMenu>
            <span>
              <Button size="sm" color="primary" className="mr-3 btn-transition-none" onClick={openBaterPontoModal}>
                Bater ponto
              </Button>
            </span>
          </HeaderMenu>
        </div>
        <div className="app-header--pane">
          <HeaderUserbox />
        </div>
      </div>

      <Modal zIndex={2000} centered={true} isOpen={isOpen} toggle={openBaterPontoModal}>
        <ModalHeader toggle={openBaterPontoModal}>Controle de ponto</ModalHeader>
        <ModalBody>
          <ControlePontoForm afterSubmitFn={openBaterPontoModal} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Header;
