import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import projectLogo from '../../../assets/isgs_logo_branco.png';
import { toggleMobileSidebar, toggleSidebar } from '../../../store/ducks/global.duck';

export default function SidebarHeader() {
  const dispatch = useDispatch();

  function toggle() {
    dispatch(toggleSidebar());
  }

  function toggleMobile() {
    dispatch(toggleMobileSidebar());
  }

  return (
    <div className="app-sidebar--header">
      <div className="app-sidebar-logo">
        <Link to="#" title="ISGS" className="app-sidebar-logo">
          <img alt="ISGS" src={projectLogo} />
        </Link>
      </div>
      <button className="btn btn-sm collapse-sidebar-btn" id="CollapseSidebarTooltip" onClick={toggle}>
        <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
      </button>
      <UncontrolledTooltip target="CollapseSidebarTooltip" container=".app-sidebar" placement="right">
        Ocultar menu
      </UncontrolledTooltip>
      <button className="navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn" onClick={toggleMobile}>
        <span className="hamburger-box">
          <span className="hamburger-inner" />
        </span>
      </button>
      <button className="expand-sidebar-btn btn btn-sm" id="ExpandSidebarTooltip" onClick={toggle}>
        <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
      </button>
      <UncontrolledTooltip target="ExpandSidebarTooltip" container=".app-sidebar" placement="right">
        Exibir menu
      </UncontrolledTooltip>
    </div>
  );
}
