import React, { useState } from 'react';
import SidebarNavItem from './SidebarNavItem';

export interface MenuItem {
  label: string;
  to?: string;
  icon?: string;
  content?: MenuItem[];
}

export interface SidebarNavProps {
  items: MenuItem[];
}

const SidebarNav: React.FC<SidebarNavProps> = props => {
  const [lastItemClicked, setLastClickedItem] = useState('');

  const toggleItem = event => {
    event.preventDefault();
    setLastClickedItem(lastItemClicked === event.currentTarget.innerText ? '' : event.currentTarget.innerText);
  };

  return (
    <ul>
      {props.items.map((item, index) => (
        <SidebarNavItem key={index} item={item} onClick={toggleItem} isOpen={item.label === lastItemClicked} />
      ))}
    </ul>
  );
};

export default SidebarNav;
