import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import menu from '../../pages/menu';
import SidebarNav from './SidebarNav';

const SidebarMenu: FC = () => {
  return (
    <PerfectScrollbar>
      <div className="sidebar-navigation">
        <SidebarNav items={menu} />
      </div>
    </PerfectScrollbar>
  );
};

export default SidebarMenu;
