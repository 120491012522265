import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { reducers } from './ducks';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(reducers, {},
                          compose(
                            applyMiddleware(sagaMiddleware),
                            window['__REDUX_DEVTOOLS_EXTENSION__'] ? window['__REDUX_DEVTOOLS_EXTENSION__']() : f => f,
                          ),
);


export { store };
