import { createLoadable } from 'summer';

const pages = [
  {
    component: createLoadable(() => import('./moduloPrincipal/ModuloPrincipal'), 'ModuloPrincipal'),
    path: '/home',
  },
  {
    component: createLoadable(() => import('./usuario/AlteracaoSenhaProcessView'), 'AlteracaoSenhaProcessView'),
    path: '/alteracaoSenhaProcessView',
  },
  {
    component: createLoadable(() => import('./usuario/UsuarioSearchView'), 'UsuarioSearchView'),
    exact: true,
    path: '/usuarioSearchView',
  },
  {
    component: createLoadable(() => import('./usuario/UsuarioEditView'), 'UsuarioEditView'),
    path: '/usuarioSearchView/edit',
  },
  {
    component: createLoadable(() => import('./setor/SetorSearchView'), 'SetorSearchView'),
    exact: true,
    path: '/setorSearchView',
  },
  {
    component: createLoadable(() => import('./setor/SetorEditView'), 'SetorEditView'),
    path: '/setorSearchView/edit',
  },
  {
    component: createLoadable(() => import('./cartaoPonto/CartaoPontoReportView'), 'CartaoPontoReportView'),
    path: '/cartaoPontoReportView',
  },
  {
    component: createLoadable(() => import('./saldo/SaldoSearchView'), 'SaldoSearchView'),
    exact: true,
    path: '/saldoSearchView',
  },
  {
    component: createLoadable(() => import('./saldo/SaldoEditView'), 'SaldoEditView'),
    path: '/saldoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./abono/AbonoSearchView'), 'AbonoSearchView'),
    exact: true,
    path: '/abonoSearchView',
  },
  {
    component: createLoadable(() => import('./abono/AbonoEditView'), 'AbonoEditView'),
    path: '/abonoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./lancamentoSimplificado/LancamentoPontoProcessView'), 'LancamentoPontoProcessView'),
    path: '/lancamentoPontoProcessView',
  },
  {
    component: createLoadable(() => import('./controlePonto/ControlePontoSearchView'), 'ControlePontoSearchView'),
    exact: true,
    path: '/controlePontoSearchView',
  },
  {
    component: createLoadable(() => import('./controlePonto/ControlePontoEditView'), 'ControlePontoEditView'),
    path: '/controlePontoSearchView/edit',
  },
  {
    component: createLoadable(() => import('./administradora/AdministradoraEditView'), 'AdministradoraEditView'),
    path: '/administradoraEditView',
  },
];

export default pages;
