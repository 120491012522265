import { faFacebook, faInstagram, faLinkedin, faTelegram, faTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React, { FC } from 'react';

import './Networks.css';

export interface NetworksProps {
  className?: string;
}

const Networks: FC<NetworksProps> = props => {
  return (
    <ul className={cx('networks', props.className)}>
      <li>
        <a href="https://www.youtube.com/user/ProjetusTI" target="_blank" rel="noopener noreferrer" title="Youtube - Projetus TI">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/projetusti" target="_blank" rel="noopener noreferrer" title="Facebook - Projetus TI">
          <FontAwesomeIcon icon={faFacebook} />
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/projetusti/" target="_blank" rel="noopener noreferrer" title="Instagram - Projetus TI">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
      </li>
      <li>
        <a href="https://twitter.com/projetusti" target="_blank" rel="noopener noreferrer" title="Twitter - Projetus TI">
          <FontAwesomeIcon icon={faTwitter} />
        </a>
      </li>
      <li>
        <a href="https://br.linkedin.com/company/projetus-ti" target="_blank" rel="noopener noreferrer" title="LinkedIn - Projetus TI">
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </li>
      <li>
        <a href="https://web.whatsapp.com/send?phone=32999682691" target="_blank" rel="noopener noreferrer" title="WhatsApp - Projetus TI">
          <FontAwesomeIcon icon={faWhatsapp} />
        </a>
      </li>
      <li>
        <a href="https://t.me/calimaerpcontabil" target="_blank" rel="noopener noreferrer" title="Telegram - Projetus TI">
          <FontAwesomeIcon icon={faTelegram} />
        </a>
      </li>
    </ul>
  );
};

export default Networks;
