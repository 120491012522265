import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Form, Row } from 'reactstrap';
import { ACTION_EVENTS, alertManager, AlertType, ERROR, progressActions, showToast } from 'summer';
import { ControlePonto } from '../../../models/dtos/controlePonto';
import { baterPonto, gerarRecibo } from '../../../services/ger.service';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';
import ServerDateTime from '../../components/serverDateTime/ServerDateTime';

interface ControlePontoFormProps {
  afterSubmitFn: () => void;
}

const ControlePontoForm: FC<ControlePontoFormProps> = ({ afterSubmitFn }) => {
  const { handleSubmit } = useForm({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);

  const onSubmit = async (data: any, event: any) => {
    event.preventDefault();
    if (globalParameter.usuario) {
      dispatch(
        progressActions.showProgress({
          animated: true,
          label: 'Batendo ponto, por favor aguarde...',
          now: 100,
        })
      );
      baterPonto(globalParameter.usuario, {
        errorFunction: mensagem => {
          showToast(mensagem, ERROR);
          dispatch(progressActions.hideProgress());
        },
        thenFunction: (recibo: ControlePonto) => {
          dispatch(progressActions.hideProgress());
          afterSubmitFn();
          gerarRecibo(recibo, {
            thenFunction: result => result,
          });
          alertManager.emit(ACTION_EVENTS.SHOW, {
            message: 'Operação realizada com sucesso. Em breve você receberá um e-mail com o recibo.',
            type: AlertType.INFORMATION,
          });
        },
      });
    }
  };
  return (
    <Form noValidate={true} onSubmit={handleSubmit(onSubmit)}>
      <Row className="justify-content-center mt-1">
        <h5>
          Olá <strong>{globalParameter.usuario && globalParameter.usuario.nomeCompleto}</strong>,
        </h5>
        <h5>Clique em ok para bater o ponto.</h5>
      </Row>
      <Row className="justify-content-center my-2">
        <h5>
          <ServerDateTime />
        </h5>
      </Row>
      <Row className="justify-content-center mx-3">
        <Col>
          <Button type="submit" color="first" block={true}>
            OK
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ControlePontoForm;
