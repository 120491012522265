import cx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { NavLink } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { MenuItem } from './SidebarNav';

interface SidebarNavItemProps {
  item: MenuItem;
  isOpen: boolean;
  onClick: (event) => void;
}

const SidebarNavItem: FC<SidebarNavItemProps> = ({ item, isOpen, onClick }) => {
  const [itemOpen, setItemOpen] = useState(false);
  const [lastSubItemClicked, setLastSubItemClicked] = useState('');

  useEffect(() => {
    setItemOpen(isOpen);
  }, [isOpen]);

  const toggleItem = event => {
    event.preventDefault();
    setLastSubItemClicked(lastSubItemClicked === event.currentTarget.innerText ? '' : event.currentTarget.innerText);
  };

  const handleClick = event => {
    onClick(event);
  };

  function getItemIcon() {
    return item.icon ? <span className={cx('sidebar-icon', item.icon)} /> : null;
  }

  function navDropdown() {
    return (
      <li>
        <a href="/" onClick={handleClick} className={cx({ active: itemOpen })}>
          {getItemIcon()}
          <span className="sidebar-item-label">{item.label}</span>
          <span className="sidebar-icon-indicator">
            <ChevronRight />
          </span>
        </a>
        <Collapse isOpen={itemOpen}>
          <ul>
            {item.content.map((it, index) => (
              <SidebarNavItem key={index} item={it} onClick={toggleItem} isOpen={it.label === lastSubItemClicked} />
            ))}
          </ul>
        </Collapse>
      </li>
    );
  }

  function navLink() {
    const url = item.to || '';
    const itemIcon = getItemIcon();
    return (
      <li>
        {isExternal(url) ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            {itemIcon}
            {item.label}
          </a>
        ) : (
          <NavLink to={url}>
            {itemIcon}
            {item.label}
          </NavLink>
        )}
      </li>
    );
  }

  function isExternal(url: string) {
    const link = url ? url.substring(0, 4) : '';
    return link === 'http';
  }

  return item.content ? navDropdown() : navLink();
};

export default SidebarNavItem;
