import { AxiosInstance } from 'axios';
import { AxiosParam, create, send } from 'summer';
import { Administradora } from '../models/dtos/administradora';
import { ControlePonto } from '../models/dtos/controlePonto';
import { LancamentoPonto } from '../models/dtos/lancamentoPonto';
import { Saldo } from '../models/dtos/saldo';
import { SaldoAlteracaoLote } from '../models/dtos/saldoAlteracaoLote';
import { Usuario } from '../models/dtos/usuario';

function getService(): AxiosInstance {
  return create('/ger');
}

export function findAdministradora(param: AxiosParam<Administradora>) {
  send(getService().get<Administradora>('/findAdministradora'), param);
}

export function baterPonto(usuario: Usuario, param: AxiosParam<ControlePonto>) {
  send(getService().post<ControlePonto>('/baterPonto', usuario), param);
}

export function findAllPontosByExpression(usuarios: Usuario[], dtInicial: Date, dtFinal: Date, param: AxiosParam<ControlePonto[]>) {
  send(
    getService().post<ControlePonto[]>('/findAllPontosByExpression', {
      dtFinal,
      dtInicial,
      usuarios,
    }),
    param
  );
}

export function gerarRecibo(controlePonto: ControlePonto, param: AxiosParam<any>) {
  send(getService().post('/gerarRecibo', controlePonto), param);
}

export function lancarPonto(lancamentoPonto: LancamentoPonto, param: AxiosParam<any>) {
  send(getService().post('/lancarPonto', lancamentoPonto), param);
}

export function obterDateTime(param: AxiosParam<string>) {
  send(getService().get<string>('/obterDateTime'), param);
}

export function findSaldo(usuario: Usuario, data: Date, param: AxiosParam<Saldo>) {
  send(
    getService().post<Saldo>('/findSaldo', { usuario, data }),
    param
  );
}

export function alterarSaldoEmLote(saldoAlteracaoLote: SaldoAlteracaoLote, param: AxiosParam<void>) {
  send(getService().post<void>('/alterarSaldoEmLote', saldoAlteracaoLote), param);
}
