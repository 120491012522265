import { createAction, handleActions } from 'redux-actions';
import { Usuario } from '../../models/dtos/usuario';

export interface GlobalParameter {
  usuario: Usuario;
}

export interface GlobalState {
  globalParameter: GlobalParameter;
  sidebarToggle: boolean;
  sidebarToggleMobile: boolean;
  headerSearchHover: boolean;
  headerDrawerToggle: boolean;
}

const initialState: GlobalState = {
  globalParameter: null,
  headerDrawerToggle: false,
  headerSearchHover: false,
  sidebarToggle: false,
  sidebarToggleMobile: false,
};

export const setGlobalParameter = createAction('SET_GLOBAL_PARAMETER');
export const toggleSidebar = createAction('TOGGLE_SIDEBAR');
export const toggleMobileSidebar = createAction('TOGGLE_MOBILE_SIDEBAR');
export const toggleHeaderSearch = createAction('TOGGLE_HEADER_SEARCH');
export const toggleHeaderDrawer = createAction('TOGGLE_HEADER_DRAWER');

export const reducer = handleActions<GlobalState>(
  {
    [setGlobalParameter.toString()]: (state: GlobalState, action: any) => ({
      ...state,
      globalParameter: action.payload,
    }),
    [toggleSidebar.toString()]: (state: GlobalState) => ({
      ...state,
      sidebarToggle: !state.sidebarToggle,
      sidebarToggleMobile: false,
    }),
    [toggleMobileSidebar.toString()]: (state: GlobalState) => ({
      ...state,
      sidebarToggle: false,
      sidebarToggleMobile: !state.sidebarToggleMobile,
    }),
    [toggleHeaderSearch.toString()]: (state: GlobalState) => ({
      ...state,
      headerSearchHover: !state.headerSearchHover,
    }),
    [toggleHeaderDrawer.toString()]: (state: GlobalState) => ({
      ...state,
      headerDrawerToggle: !state.headerDrawerToggle,
    }),
  },
  initialState
);
