import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AlertContainer, environment, Progress } from 'summer';
import Routes from './Routes';
import { store } from './store';

import './App.css';

const App: FC = () => {
  const baseName: string = environment.production ? window.location.pathname : '/';
  return (
    <Provider store={store}>
      <BrowserRouter basename={baseName}>
        <ToastContainer position="top-right" newestOnTop={true} />
        <AlertContainer />
        <Progress />
        <Routes />
      </BrowserRouter>
    </Provider>
  );
};

export default App;
