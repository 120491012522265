import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import React, { FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, UncontrolledPopover } from 'reactstrap';
import { Reducers } from '../../../store/ducks';
import { GlobalState, toggleHeaderSearch } from '../../../store/ducks/global.duck';
import menu from '../../pages/menu';

const fullMenu = [
  {
    menu,
    name: 'Módulo Principal',
  },
];

const HeaderSearch: FC = () => {
  const { headerSearchHover } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const dispatch = useDispatch();
  const history = useHistory();

  const [currentSearch, setCurrentSearch] = useState([]);

  const inputRef = useRef<HTMLInputElement>();

  const toggleHeaderSearchHover = () => {
    setCurrentSearch([]);
    if (headerSearchHover) {
      inputRef.current.value = '';
    }
    dispatch(toggleHeaderSearch());
  };

  const removerAcentos = text => {
    const mapaAcentosHex = {
      a: /[\xE0-\xE6]/g,
      c: /\xE7/g,
      e: /[\xE8-\xEB]/g,
      i: /[\xEC-\xEF]/g,
      n: /\xF1/g,
      o: /[\xF2-\xF6]/g,
      u: /[\xF9-\xFC]/g,
    };

    let clean = text.toLowerCase();

    Object.keys(mapaAcentosHex).forEach(key => {
      clean = clean.replace(mapaAcentosHex[key], key);
    });

    return clean;
  };

  const onChange = () => {
    const value = removerAcentos(inputRef.current.value);

    const copy = item => Object.assign({}, item);

    const filter = item => {
      let result = false;
      if (item.content !== undefined && item.content.length > 0) {
        item.content = item.content.map(copy).filter(filter);
        result = item.content.length > 0;
      } else {
        result = removerAcentos(item.label).indexOf(value) !== -1;
      }
      return result;
    };

    setCurrentSearch(
      value.length >= 1
        ? fullMenu
            .map(copy)
            .map(it => {
              it.menu = it.menu.map(copy).filter(filter);
              return it;
            })
            .filter(it => it.menu.length > 0)
        : []
    );
  };

  const goTo = link => e => {
    e.preventDefault();
    history.push(link);
  };

  const renderItem = (itemMenu, index) => (
    <li key={index}>
      {itemMenu.content && itemMenu.content.length > 0 ? (
        <>
          {itemMenu.label}
          <ul>{itemMenu.content.map((it, idx) => renderItem(it, idx))}</ul>
        </>
      ) : (
        <Button color="link" onClick={goTo(itemMenu.to)}>
          {itemMenu.label}
        </Button>
      )}
    </li>
  );

  const renderMenu = (main, index) => (
    <li key={index}>
      {main.name}
      <ul>{main.menu.map((it, idx) => renderItem(it, idx))}</ul>
    </li>
  );

  const waitToggle = () => setTimeout(() => toggleHeaderSearchHover(), 200);

  return (
    <div className="header-search-wrapper">
      <div className={cx('search-wrapper', { 'is-active': headerSearchHover })}>
        <label className="icon-wrapper text-black" htmlFor="header-search-input">
          <FontAwesomeIcon icon={['fas', 'search']} />
        </label>
        <input
          ref={inputRef}
          onFocus={toggleHeaderSearchHover}
          onBlur={waitToggle}
          onChange={onChange}
          className="form-control"
          id="header-search-input"
          name="header-search-input"
          type="search"
          autoComplete="off"
        />
        <UncontrolledPopover
          target="header-search-input"
          isOpen={headerSearchHover && currentSearch.length > 0}
          container="body"
          className="popover-custom-wrapper popover-custom-lg popover-global-search"
          placement="auto"
        >
          <div className="global-search">
            <ul>{currentSearch.map(renderMenu)}</ul>
          </div>
        </UncontrolledPopover>
      </div>
    </div>
  );
};

export default HeaderSearch;
