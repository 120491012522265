import { MenuItem } from '../components/sidebar/SidebarNav';

const menu: MenuItem[] = [
  {
    icon: 'pe-7s-home',
    label: 'Home',
    to: '/home',
  },
  {
    icon: 'pe-7s-config',
    label: 'Administradora',
    to: '/administradoraEditView',
  },
  {
    content: [
      {
        label: 'Usuários',
        to: '/usuarioSearchView',
      },
      {
        label: 'Setores',
        to: '/setorSearchView',
      },
    ],
    icon: 'pe-7s-edit',
    label: 'Manutenção',
  },
  {
    content: [
      {
        label: 'Ajustes no banco de horas',
        to: '/saldoSearchView',
      },
      {
        label: 'Abonos para os gráficos de efetividade',
        to: '/abonoSearchView',
      },
      {
        label: 'Lançamentos Simplificados',
        to: '/lancamentoPontoProcessView',
      },
      {
        label: 'Controle de Ponto',
        to: '/controlePontoSearchView',
      },
    ],
    icon: 'pe-7s-note2',
    label: 'Ponto',
  },
  {
    content: [
      {
        label: 'Cartão de Ponto Calculado',
        to: '/cartaoPontoReportView',
      },
    ],
    icon: 'pe-7s-print',
    label: 'Relatórios',
  },
];

export default menu;
