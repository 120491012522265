import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { loginActions } from 'summer';
import { Reducers } from '../../../store/ducks';
import { GlobalState } from '../../../store/ducks/global.duck';

export default function HeaderUserbox() {
  const { globalParameter } = useSelector<Reducers, GlobalState>(state => state.globalReducer);
  const dispatch = useDispatch();

  function logout() {
    dispatch(loginActions.logout());
  }

  return (
    <UncontrolledDropdown className="user-box position-relative ml-2">
      <DropdownToggle color="link" className="p-0 text-left d-flex align-items-center">
        <div className="d-none d-xl-block pl-2">
          <div className="font-weight-bold">{globalParameter && globalParameter.usuario.nomeCompleto}</div>
          <span className="text-black-50">{globalParameter && globalParameter.usuario.email}</span>
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </DropdownToggle>
      <DropdownMenu right={true} className="dropdown-menu-lg overflow-hidden p-0">
        <ListGroup flush={true} className="text-left bg-transparent">
          <ListGroupItem className="rounded-top">
            <Nav pills={true} className="nav-neutral-primary flex-column">
              <NavItem>
                <NavLink tag={Link} to="/alteracaoSenhaProcessView">
                  Alterar Senha
                </NavLink>
              </NavItem>
            </Nav>
          </ListGroupItem>
          <ListGroupItem className="rounded-bottom p-3 text-center">
            <Button onClick={logout} color="dark" size="sm" id="btnLogoutTooltip">
              Sair <FontAwesomeIcon icon="sign-out-alt" className="ml-2" />
            </Button>
            <UncontrolledTooltip target="btnLogoutTooltip" placement="bottom">
              Sair
            </UncontrolledTooltip>
          </ListGroupItem>
        </ListGroup>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
