import cx from 'classnames';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { currentDate } from 'summer';
import { Reducers } from '../../../store/ducks';
import { GlobalState, toggleMobileSidebar } from '../../../store/ducks/global.duck';
import Header from '../header/Header';
import Networks from '../networks/Networks';
import './sidebar.css';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';

const Sidebar: FC = ({ children }) => {
  const { sidebarToggle, sidebarToggleMobile, headerDrawerToggle, headerSearchHover } = useSelector<Reducers, GlobalState>(
    state => state.globalReducer
  );
  const dispatch = useDispatch();

  const closeMobileSidebar = () => {
    if (sidebarToggleMobile) {
      dispatch(toggleMobileSidebar());
    }
  };

  return (
    <div
      className={cx('app-wrapper app-sidebar-fixed app-header-fixed', {
        'app-sidebar-collapsed': sidebarToggle,
        'app-sidebar-mobile-open': sidebarToggleMobile,
        'header-drawer-open': headerDrawerToggle,
        'search-wrapper-open': headerSearchHover,
      })}
    >
      <div className="app-sidebar app-sidebar--dark app-sidebar--shadow">
        <SidebarHeader />
        <div className="app-sidebar--content">
          <SidebarMenu />
        </div>
      </div>
      <div className="app-main" onClick={closeMobileSidebar}>
        <Header />
        <div className="app-content">
          <div className="app-content--inner">
            <div className="app-content--inner__wrapper">{children}</div>
          </div>
          <div className="app-footer text-black-50 app-footer--shadow d-flex">
            <Row className="w-100">
              <Col className="d-flex align-items-center">
                <a href="https://www.projetusti.com.br/" target="_blank" rel="noopener noreferrer">
                  ISGS
                </a>{' '}
                &copy; {currentDate().getFullYear()}
              </Col>
              <Col className="d-flex justify-content-center">
                <Networks className="networks-footer" />
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <div className="d-inline-block powered-by">
                  <span className="d-inline-block mr-1">Desenvolvido por</span>
                  <a href="https://www.projetusti.com.br/" target="_blank" rel="noopener noreferrer">
                    Projetus TI
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
