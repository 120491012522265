import { AnimatePresence, motion } from 'framer-motion';
import React, { FC, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ClockLoader } from 'react-spinners';
import { createLoadable, LoginState, Reducers, SuspenseLoading } from 'summer';
import Sidebar from './views/components/sidebar/Sidebar';
import pages from './views/pages/routes';

const LoginForm = createLoadable(() => import('./views/pages/login/LoginForm'), 'LoginForm');
const allPaths = pages.map(it => it.path);

const pageVariants = {
  in: {
    opacity: 1,
    scale: 1,
  },
  initial: {
    opacity: 0,
    scale: 0.99,
  },
  out: {
    opacity: 0,
    scale: 1.01,
  },
};

const pageTransition = {
  duration: 0.4,
  ease: 'anticipate',
  type: 'tween',
};

const Routes: FC = () => {
  const loginReducer = useSelector<Reducers, LoginState>(state => state.loginReducer);
  const location = useLocation();

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading loader={<ClockLoader color="#3c44b1" />} />}>
        <Switch>
          <Route exact={true} path={['/']}>
            <Switch location={location} key={location.pathname}>
              <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                <Route exact={true} path="/" component={LoginForm} />
              </motion.div>
            </Switch>
          </Route>

          <Route path={allPaths}>
            <Sidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
                  {pages.map(({ exact, component, ...routeProps }, i) => {
                    let result;
                    if (loginReducer.param) {
                      result = <Route key={i} exact={exact} component={component} {...routeProps} />;
                    } else {
                      result = <Redirect key={i} to="/" />;
                    }
                    return result;
                  })}
                </motion.div>
              </Switch>
            </Sidebar>
          </Route>
        </Switch>
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
