import { combineReducers, Reducer } from 'redux';
import { loginReducer, LoginState, progressReducer, ProgressState } from 'summer';
import { GlobalState, reducer as globalReducer } from './global.duck';

export interface Reducers {
  loginReducer: LoginState;
  globalReducer: GlobalState;
  progressReducer: ProgressState;
}

export const reducers: Reducer<Reducers> = combineReducers({
  globalReducer,
  loginReducer,
  progressReducer,
});
