import React, { FC, useEffect, useState } from 'react';
import { addMilliseconds, currentDate, formatDateTime, toDateTime, useInterval } from 'summer';
import { obterDateTime } from '../../../services/ger.service';

const ServerDateTime: FC = () => {
  const delay = 1000;
  const [serverDate, setServerDate] = useState(currentDate);

  useInterval(() => {
    const newDate = addMilliseconds(serverDate, delay);
    if (newDate) {
      setServerDate(newDate);
    }
  }, delay);
  useEffect(() => {
    obterDateTime({
      thenFunction: result => {
        setServerDate(toDateTime(result));
      },
    });
  }, []);
  return <div>Horário Servidor: {formatDateTime(serverDate)}</div>;
};

export default ServerDateTime;
